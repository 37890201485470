<template>
  <ion-page>
    <ion-content>
      <div class="login-page">
        <img src="@/assets/logo.png" alt="Okalore" class="login-page__img" />
        <ion-item>
          <ion-label position="floating">Emaila</ion-label>
          <ion-input
            :value="credentials.email"
            @ionInput="credentials.email = $event.target.value"
          >
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Pasahitza</ion-label>
          <ion-input
            type="password"
            :value="credentials.password"
            @ionInput="credentials.password = $event.target.value"
          >
          </ion-input>
        </ion-item>

        <ion-button
          @click="loginHandle"
          :value="credentials.email"
          @ionInput="credentials.email = $event.target.value"
          mode="ios"
          expand="block"
          size="medium"
          >Saioa hasi</ion-button
        >

        <!-- <div id="mapContainer" class="basemap"></div> -->

        <div class="login-page__copy">OKALORE VERSION 1.0</div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  toastController,
  loadingController,
} from "@ionic/vue";

import { ref, onMounted } from "vue";

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Login",
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = ref(null);
    const credentials = ref({
      email: null,
      password: null,
    });

    onMounted(async () => {
      const token = await Storage.get({ key: "_userToken" });
      if (JSON.parse(token.value)) auth.value = JSON.parse(token.value).token;
    });

    const loginHandle = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        mode: "ios",
      });

      await loading.present();

      store.dispatch("_login", credentials.value).then(async (res) => {
        loading.dismiss();

        if (res.data.status_code === 200) {
          await Storage.set({
            key: "_userToken",
            value: JSON.stringify({
              token: res.data.access_token,
              user: res.data.user,
            }),
          });

          await Storage.set({
            key: "_sensor",
            value: JSON.stringify({
              pass: true,
            }),
          });

          router.push("/home");
        } else {
          const toast = await toastController.create({
            message: "Ez duzu sartzeko baimenik",
            duration: 3000,
          });
          toast.present();
        }
      });
    };

    return {
      credentials,
      auth,
      loginHandle,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  height: 100%;

  &__img {
    width: 60px;
    margin: 10px 0;
  }

  &__copy {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: rgba(black, 0.6);
    font-size: 9px;
  }

  ion-item,
  ion-button {
    width: 100%;
  }

  ion-button {
    margin-top: 30px;
  }
}
</style>
